import { extendTheme } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { theme } from '@/app/theme';
import { themeRadiuses } from '../constants';
import { TUserTheme } from '../types/UserTheme';
import setColors from './setColors';

const useSetTheme = (userTheme?: TUserTheme) => {
  const [newTheme, setNewTheme] = useState({ ...theme });

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight;
      setNewTheme((prevTheme) => ({
        ...prevTheme,
        space: {
          ...prevTheme.space,
          dvh100: `${vh}px`,
        },
      }));
    };

    if (userTheme) {
      setNewTheme((prevTheme) => ({
        ...prevTheme,
        colors: userTheme.colors ? setColors(userTheme.colors, prevTheme.colors) : prevTheme.colors,
        radii: userTheme.radius ? themeRadiuses[userTheme.radius] : prevTheme.radii,
      }));
    }

    setVh();
    window.addEventListener('resize', setVh);
    return () => window.removeEventListener('resize', setVh);
  }, [userTheme]);

  return extendTheme(newTheme);
};

export default useSetTheme;

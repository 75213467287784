import type { GraphQLErrorExtensions } from 'graphql';
import { PASSWORD_LOGIN_ERROR_TEXT, UNKNOWN_ERROR_TEXT } from '../constants';

const INVALID_FIELD = 'INVALID_FIELD';
const INVALID_PASSWORD = 'INVALID_PASSWORD';
const INVALID_LOGIN = 'INVALID_LOGIN';
const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

interface IGraphQLError {
  message?: string;
  locations?: readonly { line: number; column: number }[];
  path?: readonly (string | number)[];
  extensions?: GraphQLErrorExtensions;
}

export const parseGqlError = (graphQLErrors: IGraphQLError[] = []) => {
  const firstError = graphQLErrors[0];
  const extensions = firstError?.extensions;
  if (extensions) {
    switch (extensions?.code) {
      case INVALID_FIELD:
        return {
          message: INVALID_FIELD,
          code: INVALID_FIELD,
        };
      case 404:
        return {
          message: 'Not found',
          code: 404,
        };
      case 402:
        return {
          message: 'Payment required', // TODO нужен другой текст или вместо тоста модалка с тарифами или покупкой отклика
          code: 402,
        };
      case INVALID_LOGIN:
        return {
          message: PASSWORD_LOGIN_ERROR_TEXT,
          code: INVALID_LOGIN,
        };
      case INVALID_PASSWORD:
        return {
          message: PASSWORD_LOGIN_ERROR_TEXT,
          code: INVALID_PASSWORD,
        };
      default:
        break;
    }
  }
  return {
    message: UNKNOWN_ERROR_TEXT,
    code: firstError?.extensions?.code,
    type: UNKNOWN_ERROR,
  };
};

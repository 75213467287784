const primary = {
  50: '#FFE9E1',
  100: '#FECEC3',
  200: '#F7A491',
  300: '#F28469',
  400: '#F07256',
  500: '#EE6241',
  600: '#ED5331',
  700: '#BA2D0D',
  800: '#901D04',
  900: '#520500',
  light: '#FFE9E1',
  medium: '#F28469',
  base: '#EE6241',
  dark: '#901D04',
};

const secondary = {
  50: '#F6F8FE',
  100: '#CFD5E3',
  200: '#9BA8BF',
  300: '#62759D',
  400: '#364159',
  500: '#1E2533',
  600: '#12171F',
  700: '#0D1018',
  800: '#040B16',
  900: '#02060F',
  light: '#F6F8FE',
  medium: '#62759D',
  base: '#1E2533',
  dark: '#10141D',
};

const gray = {
  50: '#F7FAFC',
  100: '#EDF2F7',
  200: '#E2E8F0',
  300: '#CBD5E0',
  400: '#A0AEC0',
  500: '#718096',
  600: '#4A5568',
  700: '#2D3748',
  800: '#1A202C',
  900: '#171923',
  light: '#F7FAFC',
  medium: '#CBD5E0',
  base: '#4A5568',
  dark: '#1A202C',
};

const transparent = {
  light: '#0000003b',
  dark: '#00000075',
};

const bg = {
  50: '#F7FAFC',
  100: '#EDF2F7',
  200: '#E2E8F0',
  300: '#CBD5E0',
  400: '#A0AEC0',
  500: '#718096',
  600: '#4A5568',
  700: '#2D3748',
  800: '#1A202C',
  900: '#171923',
  pure: 'white',
  light: '#F7FAFC',
  medium: '#CBD5E0',
  base: '#4A5568',
  dark: '#1A202C',
};

const border = {
  50: '#F7FAFC',
  100: '#EDF2F7',
  200: '#E2E8F0',
  300: '#CBD5E0',
  400: '#A0AEC0',
  500: '#718096',
  600: '#4A5568',
  700: '#2D3748',
  800: '#1A202C',
  900: '#171923',
  light: '#F7FAFC',
  medium: '#CBD5E0',
  base: '#4A5568',
  dark: '#1A202C',
};

const typography = {
  50: '#F7FAFC',
  100: '#EDF2F7',
  200: '#E2E8F0',
  300: '#CBD5E0',
  400: '#A0AEC0',
  500: '#718096',
  600: '#4A5568',
  700: '#2D3748',
  800: '#1A202C',
  900: '#171923',
  pure: 'white',
  light: '#F7FAFC',
  medium: '#CBD5E0',
  base: '#4A5568',
  dark: '#1A202C',
};

const error = {
  50: '#FEE2E2',
  100: '#FECACA',
  200: '#FCA5A5',
  300: '#F87171',
  400: '#EF4444',
  500: '#E53E3E',
  600: '#DA1E28',
  700: '#BA2525',
  800: '#A61B1B',
  900: '#911111',
  pure: '#E53E3E',
  light: '#FED7D7',
  medium: '#F56565',
  transparent: '#E53E3E77',
};

const platform = {
  facebook: '#1877F2',
};

const star = {
  positive: '#FFDB56',
  negative: gray.medium,
};

const colors = {
  primary,
  secondary,
  gray,
  transparent,
  bg,
  border,
  typography,
  error,
  platform,
  star,
};

export default colors;

import { Button, HStack } from '@chakra-ui/react';
import type { ButtonProps, StackProps } from '@chakra-ui/react';
import { FC } from 'react';

export interface TControlsProps {
  positiveText?: string;
  negativeText?: string;
  onPositiveClick?: ButtonProps['onClick'];
  onNegativeClick?: ButtonProps['onClick'];
  positiveVariant?: ButtonProps['variant'];
  isLoading?: boolean;
  isPositiveDisabled?: boolean;
  isNegativeDisabled?: boolean;
  formId?: string;
  positiveType?: 'submit' | 'button';
  hidePositive?: boolean;
  containerProps?: StackProps;
  positiveButtonStyle?: ButtonProps;
  negativeButtonStyle?: ButtonProps;
  dataGtm?: string;
  size?: ButtonProps['size'];
}

const Controls: FC<TControlsProps> = ({
  positiveText = 'Ok',
  negativeText = 'Cancel',
  onPositiveClick = () => {},
  onNegativeClick,
  positiveVariant,
  isLoading,
  positiveType = 'button',
  isPositiveDisabled,
  isNegativeDisabled,
  hidePositive = false,
  containerProps = {},
  positiveButtonStyle = {},
  negativeButtonStyle = {},
  dataGtm,
  size,
}) => {
  const showNegative = !!onNegativeClick;

  return (
    <HStack
      justifyContent='flex-end'
      width='full'
      {...containerProps}
    >
      {showNegative && (
        <Button
          isDisabled={isLoading || isNegativeDisabled}
          size={size}
          variant='outline'
          width={{ base: 'full', sm: 32 }}
          onClick={onNegativeClick}
          {...negativeButtonStyle}
        >
          {negativeText}
        </Button>
      )}
      {!hidePositive && (
        <Button
          data-gtm={dataGtm}
          isDisabled={isPositiveDisabled}
          isLoading={isLoading}
          size={size}
          type={positiveType}
          variant={positiveVariant}
          width={{ base: 'full', sm: 32 }}
          onClick={onPositiveClick}
          {...positiveButtonStyle}
        >
          {positiveText}
        </Button>
      )}
    </HStack>
  );
};

export default Controls;

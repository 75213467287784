import { ModalOverlay as ChakraModalOverlay } from '@chakra-ui/react';
import type { ModalOverlayProps } from '@chakra-ui/react';
import { FC } from 'react';

const ModalOverlay: FC<ModalOverlayProps> = (props) => (
  <ChakraModalOverlay
    backdropFilter='blur(1px)'
    bg='blackAlpha.400'
    {...props}
  />
);

export default ModalOverlay;

const Textarea = {
  variants: {
    outline: {
      _placeholder: {
        color: 'typography.medium',
      },
      bg: 'bg.pure',
    },
  },
};

export default Textarea;

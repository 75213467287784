import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

export default defineMultiStyleConfig({
  variants: {
    outline: {
      field: {
        _placeholder: {
          color: 'typography.medium',
        },
        bg: 'bg.pure',
      },
    },
  },
});

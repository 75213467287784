import { useRouter } from 'next/router';
import { CombinedError } from 'urql';
import { useStore as useZustandStore } from 'zustand';
import { useMutation } from '@/sharedAPI';
import { getRefreshToken } from '@/sharedAPI';
import { useToast } from '@/sharedUI';
import { TBlockKey, TBlockType, config, parseGqlError } from '@/sharedLib';
import { PUBLISH_LANDING_CONFIG, UPDATE_LANDING_EDIT_CONFIG, UPDATE_SUBDOMAIN } from '../gql';
import { TFullConfigState } from './configStore';
import { useConfigStore } from './configStoreProvider';

const useStore = <T,>(selector: (store: TFullConfigState) => T): T => {
  const store = useConfigStore();

  return useZustandStore(store, selector);
};

type TSelector<T> = (store: TFullConfigState) => T;

function useGetStoreData(): TFullConfigState;
function useGetStoreData<T>(selector: TSelector<T>): T;
function useGetStoreData<T>(selector?: TSelector<T>): T | TFullConfigState {
  const store = useConfigStore();

  if (!selector) {
    return store.getState();
  }

  return selector(store.getState());
}

const useStoreMethods = () => {
  const changeEditStatus = useStore((s) => s.changeEditStatus);
  const updateEditConfig = useStore((s) => s.updateEditConfig);
  const updateMainBlockEditConfig = useStore((s) => s.updateMainBlockEditConfig);
  const updateConfig = useStore((s) => s.updateConfig);
  const resetEditConfig = useStore((s) => s.resetEditConfig);
  const updateServicesEditConfig = useStore((s) => s.updateServicesEditConfig);
  const updatePortfolioEditConfig = useStore((s) => s.updatePortfolioEditConfig);
  const updateTemplate = useStore((s) => s.updateTemplate);
  const updateInfoConfig = useStore((s) => s.updateInfoConfig);
  const updatePhotoGalleryConfig = useStore((s) => s.updatePhotoGalleryConfig);
  const updateOurWorkersBlock = useStore((s) => s.updateOurWorkersBlock);
  const updateIsMobile = useStore((s) => s.updateIsMobile);
  const openConfirmPopup = useStore((s) => s.openConfirmPopup);
  const closeConfirmPopup = useStore((s) => s.closeConfirmPopup);

  return {
    changeEditStatus,
    updateConfig,
    updateEditConfig,
    resetEditConfig,
    updateServicesEditConfig,
    updateMainBlockEditConfig,
    updatePortfolioEditConfig,
    updateTemplate,
    updateInfoConfig,
    updatePhotoGalleryConfig,
    updateOurWorkersBlock,
    updateIsMobile,
    openConfirmPopup,
    closeConfirmPopup,
  };
};

const useUpdateConfig = (callback?: () => void) => {
  const { showErrorToast, showSuccessToast } = useToast();
  const router = useRouter();
  const store = useConfigStore();
  const subdomain = router?.query?.subdomain;
  const { updateConfig } = useStoreMethods();

  const [update, loading] = useMutation(UPDATE_LANDING_EDIT_CONFIG, {
    onCompleted: async (data: any) => {
      callback?.();
    },
    onError: (error: CombinedError | undefined) => {
      const parseError = parseGqlError(error?.graphQLErrors);
      showErrorToast(parseError.message);
    },
    onLoad: () => {},
  });

  const updateLandingConfig = async () => {
    const { editConfig } = store.getState();

    if (!editConfig || !subdomain) return;

    const res = await update({
      input: {
        config: JSON.stringify(editConfig),
      },
    });

    if (res.error) {
      return res;
    }

    let config;

    if (res.data?.updateLandingEditConfig?.config) {
      config = JSON.parse(res.data.updateLandingEditConfig.config);

      updateConfig(config);
    }

    showSuccessToast('Data saved successfully');

    return res;
  };

  return { updateLandingConfig, loading };
};

const useChangeBlocksList = (callback?: () => void) => {
  const removeBlock = useStore((s) => s.removeBlock);
  const addBlock = useStore((s) => s.addBlock);
  const changeBlockPosition = useStore((s) => s.changeBlockPosition);

  const { updateLandingConfig, loading } = useUpdateConfig(callback);

  const remove = (name: string, index?: number) => {
    removeBlock(name, index);
    return updateLandingConfig();
  };

  const add = (key: TBlockKey, position: number, type: TBlockType) => {
    addBlock({ key, type }, position);
    return updateLandingConfig();
  };

  const upPosition = (name: TBlockKey, index?: number) => {
    changeBlockPosition(name, -1, index);
    return updateLandingConfig();
  };

  const downPosition = (name: TBlockKey, index?: number) => {
    changeBlockPosition(name, 1, index);
    return updateLandingConfig();
  };

  return {
    remove,
    add,
    downPosition,
    upPosition,
    loading,
  };
};

const useUpdateSubdomain = () => {
  const { showErrorToast, showSuccessToast } = useToast();
  const router = useRouter();

  const { updateConfig } = useStoreMethods();

  const [update, loading] = useMutation(UPDATE_SUBDOMAIN, {
    onCompleted: async (data: any) => {
      if (data?.updateSubdomain?.config) {
        const newConfig = JSON.parse(data?.updateSubdomain?.config);
        updateConfig(newConfig);
        const refreshToken = getRefreshToken();

        router.replace(
          `${config.protocol}${data?.updateSubdomain?.subdomain}.${config.domain}/edit?token=${refreshToken}`,
        );
        showSuccessToast('Data saved successfully');
      }
    },
    onError: (error: CombinedError | undefined) => {
      const parseError = parseGqlError(error?.graphQLErrors);
      showErrorToast(parseError.message);
    },
    onLoad: () => {},
  });

  const updateSubdomain = (subdomain?: string) => {
    if (subdomain) {
      update({
        input: {
          subdomain,
        },
      });
    }
  };

  return { updateSubdomain, loading };
};

const usePublishLandingConfig = () => {
  const { showErrorToast, showSuccessToast } = useToast();

  const [publish, isLoading] = useMutation(PUBLISH_LANDING_CONFIG, {
    onCompleted: () => {
      showSuccessToast('Page published successfully');
    },
    onError: (error: CombinedError | undefined) => {
      const parseError = parseGqlError(error?.graphQLErrors);
      showErrorToast(parseError.message);
    },
    onLoad: () => {},
  });

  return {
    publish,
    isLoading,
  };
};

const model = {
  useStore,
  useGetStoreData,
  useUpdateConfig,
  useUpdateSubdomain,
  useStoreMethods,
  useChangeBlocksList,
  usePublishLandingConfig,
};

export default model;
